<template>
	<div>
		<div class="banner_info" style="background: url(images/1_02.jpg) center no-repeat;background-size: cover;">
			<dl>
				<dt>动态概括</dt>
				<dd>Dynamic generalization</dd>
			</dl>
		</div>
		<div class="infopage">
			<div class="container">
				<certification />
			</div>
			<div class="container clearfix" id="container">
				<div class="left">
					<div class="box" v-for="(item,index) in leftData" :key="item.id">
						<dl v-if="index==0">
							<dt>动态概括</dt>
							<dd>Dynamic generalization</dd>
						</dl>
						<h3 :class="{on:item.id == id,active:cId == item.id}" @click="tabClick(item,index)">
							<a href="javascript:;">{{item.name}}</a>
							<span v-if="!tabArray.includes(item.name)"><i></i></span>
						</h3>
						<ul :class="{hidden:item.id != id }" v-if="!tabArray.includes(item.name)">
							<li @click="itemClick(val,index)" :class="{on:cId == val.id && !cTab}"
								v-for="val in item.childList" :key="val.id"><a href="javascript:;">{{val.name}}</a></li>
						</ul>
					</div>

				</div>
				<div class="right" v-if="!cTab">
					<div class="title flex lg">
						<div class="flex_item"><b class="">{{tabName}}</b></div>
						<div class="bread">
							<a href="#">首页</a> > <a href="#">动态概括</a> > <span>{{tabName}}</span>
						</div>
					</div>
					<!--动态概括子菜单通用页面 -->
					<div class="newsitem" v-if="!historyStatus">
						<div class="newsitem-title">
							{{articleDetail.title}}
						</div>
						<div class="source">
							<div class="t1">
								来源:
							</div>
							<div class="t2">
								{{articleDetail.source}}
							</div>
							<div class="t3">
								时间:
							</div>
							<div class="t4">
								{{articleDetail.createTime}}
							</div>
						</div>
						<div v-html="articleDetail.content || ''">
						</div>
					</div>
					<!-- 发展历程单独页面 -->
					<div class="newsitem" v-else>
						<div class="history-title">{{history.title}}</div>
						<div class="history-summary">{{history.summary}}</div>
						<div class="history-steps-box">
							<div v-for="(item,index) in history.steplist">
								<div class="history-steps-item">
									<div class="history-years-box">
										<div class="history-years">{{item.year}}年</div>
									</div>
									<div :class="index<(history.steplist.length-1)?item.suorce?'history-desc-box1':'history-desc-box3':'history-desc-box2'">
										<div class="history-desc-title">{{item.name}}</div>
										<div class="history-desc-suorce">{{item.suorce}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="right" v-else>
					<div class="box" v-for="item in columnData" :key="item.id">
						<div class="title flex">
							<div class="flex_item"><b>{{item.name}}</b></div>
							<a href="javascript:;" @click="toMore(item.id)" class="more">更多</a>
						</div>
						<div class="bd">
							<ul class="newslist">
								<li v-for="val in item.articleList.slice(0,10)" :key="val.id">
									<!-- <div class="flex_item" @click="openNewPage(val)"><a href="javascript:;">{{val.title}}</a></div> -->
									<div class="flex_item" @click="toDetail(val.id,item.name)"><a
											href="javascript:;">{{val.title}}</a></div>
									<span>{{val.publishTime}}</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapActions,
		mapState
	} from 'vuex';
	import certification from "@/components/certification";
	export default {
		name: "generalization",
    components:{
      certification
    },
		data() {
			return {
				historyStatus:false,
				pid: 0,
				id: 0,
				index: 0,
				current: {},
				cTab: '',
				cId: 0,
				tabArray: ['中心动态', '行业动态'],
				articleDetail: '',
				childItem: [],
				columnData: [],
				leftData: [],
				history:{
					title:'中联认证中心隶属于国家中央直属大型科技企业——机械科学研究总院。',
					summary:'机械科学研究总院在长期开展行业技术推进与服务过程中，积累了丰富的经验，形成了雄厚的技术资源，组建了一批从事标准化研究、电工产品认证及机械产品安全认证和在我国第一批获得国家认可专项从事管理体系认证的专业部门和服务机构。',
					steplist:[
						{year:'2017',name:'增值更名“中联认证中心”（北京）有限公司',suorce:'中联认证中心（北京）有限公司现隶属于机械科学研究总院集团有限公司所属中机寰宇认证检验有限公司全资注册和管理，从事管理体系和产品认证的独立的第三方技术中介服务机构。'},
						{year:'2002',name:'增值更名“中联认证中心”（北京）有限公司',suorce:'中联认证中心（北京）有限公司现隶属于机械科学研究总院集团有限公司所属中机寰宇认证检验有限公司全资注册和管理，从事管理体系和产品认证的独立的第三方技术中介服务机构。'},
						{year:'2000',name:'增值更名“中联认证中心”（北京）有限公司',suorce:''},
						{year:'1997',name:'增值更名“中联认证中心”（北京）有限公司',suorce:''},
						{year:'1996',name:'增值更名“中联认证中心”（北京）有限公司',suorce:''},
						{year:'1992',name:'增值更名“中联认证中心”（北京）有限公司',suorce:''},
					],
				},
			}
		},
		mounted() {
			let route = this.$route.query
			this.pid = route?.pid
			this.id = route?.id
			this.index = route?.index
			console.log(this.id)
			console.log(this.index)
			console.log(this.pid)
			//获取数据
			this.$api.getChildColumn({
				columnId: this.pid
			}).then(res => {
				this.leftData = res.data.data;
				console.log(this.leftData);
				if (this.index == 1 || this.index == 2) {
					this.cTab = this.leftData[this.index]
					this.cId = this.leftData[this.index]?.id;
					this.findColumnData()
				} else {
					this.cId = this.leftData[this.index] && this.leftData[this.index].childList[0]?.id || 0
				}
				this.current = this.leftData[this.index].childList[0]
				if (!this.cTab) {
					let articleId = this.leftData[this.index].childList[0].articleList[0]?.id
					this.getArticleData(articleId)
				}
				console.log(this.current)
				this.$nextTick(function() {
					this.initTab()
				})
			})
		},
		methods: {
			...mapActions(['columnAction']),
			initTab() {
				$("#container .left .box h3").click(function() {
					$(this).toggleClass('on');
					$(this).next("ul").slideToggle();
				});
			},
			itemClick(item, index) {
				this.historyStatus=item.name==='发展历程'?true:false
				this.index = index
				this.current = item
				this.cTab = ''
				this.cId = item.id
				//获取文章数据
				let articleId = item.articleList[0]?.id
				this.getArticleData(articleId)
				// console.log(item)
				// this.toDetail(item.articleList[0]?.id,item.name)
			},
			async getArticleData(articleId) {
				let res = await this.$api.getArticleData({
					id: articleId
				});
				this.articleDetail = res.data.data;
				// console.log(this.articleDetail,'articleDetail');
			},
			async findColumnData() {
				let res = await this.$api.getChildColumn({
					columnId: this.cId
				});
				this.columnData = res.data.data;
				console.log(this.columnData, '--');
			},
			tabClick(item, index) {
				this.index = index
				if (this.tabArray.includes(item.name)) {
					this.cTab = item
					this.cId = item.id
					this.findColumnData();
				}
				console.log(this.cTab)
			},
			openNewPage(item) {
				console.log(item);
				let {
					href
				} = this.$router.resolve({
					path: '/detail',
					query: {
						pid: this.pid,
						id: this.cId,
						tabIndex: this.tabIndex,
						index: this.index,
						name: '动态概括',
						level: 2,
						articleId: item.id
					}
				})
				window.open(href, '_blank')
			},
			toDetail(id, name) {
				this.$router.push({
					path: '/view',
					query: {
						articleId: id,
						name: name
					}
				})
			},
			toMore(cid) {
				this.$router.push({
					path: '/list',
					query: {
						pid: cid
					}
				})
			},
		},
		computed: {
			...mapState(["cacheColumn", 'tabIndex']),
			tabName() {
				if (this.cTab) {
					return this.cTab.name
				} else {
					return this.current.name
				}
			},
		},
	}
</script>

<style scoped>

</style>
